import React, { FunctionComponent } from "react"
import { SupportingLigonier } from "../../support-types"
import { CapitalCampaign } from "../capital-campaign/capital-campaign"
import { FeaturedTestimonies } from "../featured-testimonies/featured-testimonies"
import { ListWithText } from "../list-with-text/list-with-text"
import { PartnerWithUs } from "../partner-with-us/partner-with-us"
import { Projects } from "../projects/projects"
import { SupportHeader } from "../support-header/support-header"
import styles from "./support-ligonier-page.module.scss"

export interface SupportingLigonierPageprops {
  details: SupportingLigonier
}

export const SupportingLigonierPage: FunctionComponent<
  SupportingLigonierPageprops
> = ({ details }) => {
  return (
    <div className={styles.supportingLigonier}>
      <SupportHeader {...details.header} />
      <PartnerWithUs {...details.partner} />
      <ListWithText {...details.rightNow} />
      <FeaturedTestimonies {...details.featuredTestimonies} />
      <CapitalCampaign {...details.capitalCampaign} />
      <ListWithText {...details.waysToGive} />
      <Projects {...details.projects} />
    </div>
  )
}
