import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import {
  SupportingLigonierFragment,
  SupportingLigonierPageQuery
} from "../../../graphql/types"
import { PageProps } from "../../types/page"
import { Page } from "../../components/page/page"
import { PageBreadcrumbs } from "../../core/breadcrumbs"
import { defaultMetadata, PageOpenGraphImage } from "../../core/constants"
import { SupportingLigonierPage } from "../../features/support/components/support-ligonier-page/support-ligonier-page"
import { mapSupportingLigonierToView } from "../../features/support/mappers/supporting-ligonier-mapper"
import { SupportingLigonier } from "../../features/support/support-types"

export const SupportingLigonierTemplate: FunctionComponent<
  PageProps<SupportingLigonierPageQuery>
> = ({ data, ...props }) => {
  const details: SupportingLigonier = mapSupportingLigonierToView(
    data?.contentfulAboutSupportingLigonier as SupportingLigonierFragment
  )

  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        title:
          data?.contentfulAboutSupportingLigonier?.pageTitle ||
          defaultMetadata.title,
        openGraphImage: PageOpenGraphImage.Give
      }}
      pageOptions={{ breadcrumbs: PageBreadcrumbs.Support }}
    >
      <SupportingLigonierPage details={details} />
    </Page>
  )
}

export default SupportingLigonierTemplate

export const query = graphql`
  query SupportingLigonierPage {
    contentfulAboutSupportingLigonier {
      ...SupportingLigonier
    }
  }
`
