import React, { FunctionComponent } from "react"
import { Button } from "../../../../components/button/button"
import { Carousel } from "../../../../components/carousel/carousel"
import { CapitalCampaign as CapitalCampaignProps } from "../../support-types"
import styles from "./capital-campaign.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const CapitalCampaign: FunctionComponent<CapitalCampaignProps> = ({
  title,
  image,
  body,
  buttonText,
  buttonUrl,
  list
}) => (
  // We remove the spaces in the title and use that as the id
  // This id in turn is used to navigate to this section of the page
  <div className={styles.capitalCampaign} id={title.replace(/ /g, "")}>
    <div className={styles.content}>
      <div className={styles.image}>
        <img data-src={image} alt="" className="lazyload" />
      </div>
      <div className={styles.text}>
        <Markdown className={styles.title} content={title} />
        {body && <Markdown className={styles.body} content={body} />}
      </div>
      <div className={styles.list}>
        <Carousel>
          {list.map((item) => (
            <div className={styles.listItem} key={item.title}>
              <div className={styles.image}>
                <img data-src={item.image} alt="" className="lazyload" />
              </div>
              <Markdown className={styles.title} content={item.title} />
              <Markdown className={styles.body} content={item.body} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.text}>
        <Button size="md" variant="primary" to={buttonUrl}>
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
)
