import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import styles from "./logo.module.scss"

export interface LogoProps {
  className?: string
  invert?: boolean
  size?: number
  onClick?: MouseEventHandler
}

export const Logo: FunctionComponent<LogoProps> = ({
  className,
  invert = false,
  onClick,
  size = 24
}) => {
  const clampedSize = Math.max(12, size)

  return (
    <svg
      width={clampedSize}
      height={clampedSize}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        styles.logo,
        {
          [styles.invert]: invert,
          [styles.interactive]: Boolean(onClick)
        },
        className
      )}
      onClick={onClick}
    >
      <use xlinkHref={`/icons/sprite.svg#24-logo`} />
    </svg>
  )
}
