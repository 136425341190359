import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Button } from "../../../../components/button/button"
import { Logo } from "../../../../components/logo/logo"
import { ListWithText as ListWithTextProps } from "../../support-types"
import styles from "./list-with-text.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const ListWithText: FunctionComponent<ListWithTextProps> = ({
  title,
  bodyPre,
  bodyPost,
  buttonText,
  buttonUrl,
  list
}) => (
  <div className={styles.listWithText}>
    <div className={styles.content}>
      <div className={styles.text}>
        <h2 className={styles.title}>{title}</h2>
        {bodyPre && <Markdown className={styles.body} content={bodyPre} />}
      </div>
      <div className={styles.list}>
        {list.map((item, index) => (
          <div
            className={classNames(styles.listItem, item.body && styles.full)}
            key={index}
          >
            <div className={styles.image}>
              <Logo />
            </div>
            <Markdown className={styles.title} content={item.title} />
            {item.body && (
              <Markdown className={styles.body} content={item.body} />
            )}
          </div>
        ))}
      </div>
      <div className={styles.text}>
        {bodyPost && <Markdown className={styles.body} content={bodyPost} />}
      </div>
      <div className={styles.text}>
        <Button size="md" variant="primary" to={buttonUrl}>
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
)
