import React, { FunctionComponent } from "react"
import { Button } from "../../../../components/button/button"
import { Carousel } from "../../../../components/carousel/carousel"
import { PartnerWithUs as PartnerWithUsProps } from "../../support-types"
import styles from "./partner-with-us.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const PartnerWithUs: FunctionComponent<PartnerWithUsProps> = ({
  benefits,
  benefitsButtonText,
  benefitsButtonUrl,
  body,
  body2,
  partnerButtonText,
  partnerButtonUrl,
  testimonies,
  title
}) => (
  <div className={styles.partnerWithUs}>
    <div className={styles.content}>
      <div className={styles.text}>
        <Markdown className={styles.title} content={title} />
        <Markdown className={styles.body} content={body} />
        <Button size="md" variant="primary" to={partnerButtonUrl}>
          {partnerButtonText}
        </Button>
      </div>
      <div className={styles.testimonies}>
        <Carousel>
          {testimonies.map((item, index) => (
            <div className={styles.testimony} key={index}>
              {item.image && (
                <img data-src={item.image} alt="" className="lazyload" />
              )}
              <Markdown className={styles.name} content={item.name} />
              <Markdown className={styles.body} content={item.body} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.text}>
        <Markdown className={styles.body} content={body2} />
      </div>
      <div className={styles.benefits}>
        {benefits.map((item, index) => (
          <div className={styles.benefit} key={index}>
            <div className={styles.image}>
              <img data-src={item.image} alt="" className="lazyload" />
            </div>
            <Markdown className={styles.body} content={item.text} />
          </div>
        ))}
      </div>
      <div className={styles.text}>
        <Button size="md" variant="primary" to={benefitsButtonUrl}>
          {benefitsButtonText}
        </Button>
      </div>
    </div>
  </div>
)
