import React, { FunctionComponent } from "react"
import { Button } from "../../../../components/button/button"
import { SupportHeader as SupportHeaderProps } from "../../support-types"
import styles from "./support-header.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const SupportHeader: FunctionComponent<SupportHeaderProps> = ({
  title,
  image,
  subtitle,
  buttonText,
  buttonUrl
}) => (
  <div className={styles.supportHeader}>
    <div className={styles.content}>
      <div className={styles.image}>
        <img data-src={image} alt="" className="lazyload" />
      </div>
      <div className={styles.text}>
        <Markdown className={styles.subtitle} content={subtitle} />
        <Markdown className={styles.title} content={title} />
        <Button
          size="md"
          variant="primary"
          className={styles.button}
          to={buttonUrl}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
)
