import { SupportingLigonierFragment } from "../../../../graphql/types"
import { SupportingLigonier } from "../support-types"

export const mapSupportingLigonierToView = (
  data: SupportingLigonierFragment
): SupportingLigonier => {
  return {
    header: {
      subtitle:
        data.donorBlock?.title?.childMarkdownRemark?.rawMarkdownBody || "",
      image: data.donorBlock?.image?.fluid?.src || "",
      title:
        data.donorBlock?.description?.childMarkdownRemark?.rawMarkdownBody ||
        "",
      buttonText: "Give Today",
      buttonUrl: data.donorBlock?.urlLink || ""
    },
    partner: {
      title:
        data.partnerBlock?.title?.childMarkdownRemark?.rawMarkdownBody || "",
      body:
        data.partnerBlock?.description?.childMarkdownRemark?.rawMarkdownBody ||
        "",
      partnerButtonText: "Become A Partner",
      partnerButtonUrl: data.partnerBlock?.urlLink || "",
      testimonies: (data.photoTestimonies || []).map((testimony) => ({
        name: testimony?.title?.childMarkdownRemark?.rawMarkdownBody || "",
        body:
          testimony?.description?.childMarkdownRemark?.rawMarkdownBody || "",
        image: testimony?.image?.fluid?.src || ""
      })),
      body2:
        data.partnerBenefitsCopy?.childMarkdownRemark?.rawMarkdownBody || "",
      benefits: (data.partnerBenefits || []).map((benefit) => ({
        text: benefit?.title?.childMarkdownRemark?.rawMarkdownBody || "",
        image: benefit?.image?.fluid?.src || ""
      })),
      benefitsButtonText: "Become A Partner",
      benefitsButtonUrl: data.partnerButtonUrl || ""
    },
    rightNow: {
      title: data.generalFundTitle || "",
      bodyPre: data.generalFundBody?.childMarkdownRemark?.rawMarkdownBody || "",
      list: (data.generalFundCallouts || []).map((item) => ({
        title: item?.title?.childMarkdownRemark?.rawMarkdownBody || ""
      })),
      bodyPost:
        data.generalFundBodyContinued?.childMarkdownRemark?.rawMarkdownBody ||
        "",
      buttonText: "Give Today",
      buttonUrl: data.generalFundPartnerButtonUrl || ""
    },
    featuredTestimonies: {
      image: data.testimonyBlockPhoto?.fluid?.src || "",
      items: (data.textTestimonies || []).map((testimony) => ({
        name: testimony?.title?.childMarkdownRemark?.rawMarkdownBody || "",
        body: testimony?.description?.childMarkdownRemark?.rawMarkdownBody || ""
      }))
    },
    capitalCampaign: {
      image: data.capitalCampaignBlock?.image?.fluid?.src || "",
      body:
        data.capitalCampaignBlock?.description?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      title:
        data.capitalCampaignBlock?.title?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      list: (data.capitalCampaignItems || []).map((campaign) => ({
        title: campaign?.title?.childMarkdownRemark?.rawMarkdownBody || "",
        image: campaign?.image?.fluid?.src || "",
        body: campaign?.description?.childMarkdownRemark?.rawMarkdownBody || ""
      })),
      buttonUrl: data.capitalCampaignButtonUrl || "",
      buttonText: "Learn more"
    },
    waysToGive: {
      title: data.waysToGiveTitle || "",
      list: (data.waysToGive || []).map((item) => ({
        title: item?.title?.childMarkdownRemark?.rawMarkdownBody || "",
        body: item?.description?.childMarkdownRemark?.rawMarkdownBody || ""
      })),
      bodyPost: data.waysToGiveCopy?.childMarkdownRemark?.rawMarkdownBody || "",
      buttonText: "Discover Ways to Give",
      buttonUrl: data.waysToGiveButtonUrl || ""
    },
    projects: {
      title: data.projectsTitle || "",
      body: data.closingPartnerCopy?.childMarkdownRemark?.rawMarkdownBody || "",
      list: (data.projects || []).map((project) => ({
        image: project?.image?.fluid?.src || "",
        title: project?.title?.childMarkdownRemark?.rawMarkdownBody || "",
        body: project?.description?.childMarkdownRemark?.rawMarkdownBody || "",
        url: project?.urlLink || ""
      })),
      buttonUrl: data.closingPartnerButtonUrl || "",
      buttonText: "Give Now"
    }
  }
}
