import React, { FunctionComponent } from "react"
import { Button } from "../../../../components/button/button"
import { Carousel } from "../../../../components/carousel/carousel"
import { Projects as ProjectsProps } from "../../support-types"
import styles from "./projects.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const Projects: FunctionComponent<ProjectsProps> = ({
  body,
  buttonText,
  buttonUrl,
  list,
  title
}) => (
  <div className={styles.projects}>
    <div className={styles.content}>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.list}>
        <Carousel
          className={styles.carousel}
          itemClassName={styles.carouselItem}
        >
          {list.map((item) => {
            const Tag = item.url ? "a" : "div"

            const props = {
              className: styles.listItem,
              ...(item.url ? { href: item.url } : {})
            }

            return (
              <Tag {...props} key={item.title}>
                {item.image && (
                  <img data-src={item.image} alt="" className="lazyload" />
                )}
                <Markdown className={styles.name} content={item.title} />
                <Markdown className={styles.body} content={item.body} />
              </Tag>
            )
          })}
        </Carousel>
      </div>
      <div className={styles.text}>
        <Markdown className={styles.body} content={body} />
      </div>
      <div className={styles.text}>
        <Button size="md" variant="primary" to={buttonUrl}>
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
)
